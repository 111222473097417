import useJwt from '@/auth/jwt/useJwt';

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchDepartmentOfHealths(ctx, data) {
      return useJwt.getDepartmentOfHealths(data).then(response => response);
    },
    createDepartmentOfHealth(ctx, data) {
      return useJwt.createDepartmentOfHealth(data).then(response => response);
    },
    updateDepartmentOfHealth(ctx, data) {
      return useJwt.updateDepartmentOfHealth(data).then(response => response);
    },
    deleteDepartmentOfHealth(ctx, id) {
      return useJwt.deleteDepartmentOfHealth(id).then(response => response);
    },
  },
};
