import { ref, computed } from '@vue/composition-api';
import store from '@/store';
import toast from '@/utils/toast';
import managerStoreModule from '../managerStoreModule';

export default function useManagerList(props) {
	const STORE_MODULE_NAME = "department-of-health-manager";

	// Register module
	if (!store.hasModule(STORE_MODULE_NAME)) {
		store.registerModule(STORE_MODULE_NAME, managerStoreModule);
	}

	const toastification = toast();

	const departmentOfHealthItem = computed(() => props.departmentOfHealth);

	const blankItem = {
		departmentOfHealth: null,
		full_name: '',
		phone: '',
		email: '',
		status: '',
	};
	const refModal = ref(null);
	const item = ref(JSON.parse(JSON.stringify(blankItem)));
	const resetItemLocal = () => {
		item.value = JSON.parse(JSON.stringify(blankItem));
	};
	const data = ref([]);

	const resetModal = () => {
		blankItem.departmentOfHealth = props.departmentOfHealth;
		data.value = [];
		resetItemLocal();
	};

	const fetchData = () => {
		store.dispatch('department-of-health-manager/fetchManagers', { department_of_health: departmentOfHealthItem.value._id, skipCount: 0, maxResultCount: 1000 }).then(response => {
			data.value = response.data.items;
		}).catch(error => {
			toastification.showToastError(error);
		});
	};

	const onOpen = () => {
		resetModal();
		fetchData();
	};

	const onEditItem = val => {
		if (val) {
			item.value = { ...val, departmentOfHealth: departmentOfHealthItem.value };
		} else {
			item.value = blankItem;
		}
	};

	const onDelete = val => {
		store.dispatch('department-of-health-manager/deleteManager', val._id).then(() => {
			toastification.showToastDeleteSuccess();
			fetchData();
		}).catch(error => {
			toastification.showToastError(error);
		});
	};

	return {
		refModal,
		departmentOfHealthItem,
		data,
		fetchData,
		onOpen,
		item,
		onEditItem,
		onDelete,
		resetItemLocal,
		resetModal,

	};
}
