import { ref, watch } from '@vue/composition-api';
import store from '@/store';
import toast from '@/utils/toast';
import departmentOfHealthStoreModule from '../departmentOfHealthStoreModule';

export default function useDepartmentOfHealthList() {
	const APARTMENT_STORE_MODULE_NAME = "department-of-health";

	// Register module
	if (!store.hasModule(APARTMENT_STORE_MODULE_NAME)) {
		store.registerModule(APARTMENT_STORE_MODULE_NAME, departmentOfHealthStoreModule);
	}

	const toastification = toast();

	const blankItem = {
		avatar: '',
		name: '',
		province: null,
		address_detail: '',
	};

	const item = ref(JSON.parse(JSON.stringify(blankItem)));
	const resetItem = () => {
		item.value = JSON.parse(JSON.stringify(blankItem));
	};

	// Table Handlers
	const columns = [
		{
			label: '#',
			field: 'index',
			width: '40px',
			tdClass: 'text-center',
			thClass: 'text-center',
			sortable: false,
			orderable: false,
		},
		{
			label: 'Thao tác',
			field: 'action',
			width: '100px',
			thClass: 'text-center',
			tdClass: 'text-center',
			sortable: false,
			data: '_id',
			searchable: true,
			orderable: true,
		},
		{
			label: 'Sở y tế',
			field: 'department-of-health',
			data: 'name',
			sortable: false,
			searchable: true,
			orderable: true,
		},
		{
			label: 'Địa chỉ',
			field: 'address_detail',
			data: 'address_detail',
			sortable: false,
			searchable: true,
			orderable: true,
		},
	];
	const analytics = ref();
	const rows = ref([]);
	// filter
	const province = ref(null);
	// current selected rows
	const selectedRows = ref([]);
	const searchTerm = ref('');
	// ssr
	const isLoading = ref(false);
	const totalRecords = ref(0);
	const serverParams = ref({
		skipCount: 0,
		maxResultCount: 10,
		filter: '',
	});

	//   API Call

	const fetchDoctors = () => {
		store
			.dispatch('department-of-health/fetchDepartmentOfHealths', serverParams.value)
			.then(response => {
				const { totalCount, items } = response.data;
				totalRecords.value = totalCount;
				rows.value = items;
				isLoading.value = false;
			})
			.catch(error => {
				toastification.showToastError(error);
				isLoading.value = false;
			});
	};
	const fetchData = () => {
		isLoading.value = true;
		fetchDoctors();
	};

	const deleteDepartmentOfHealth = id => {
		store
			.dispatch('department-of-health/deleteDepartmentOfHealth', id)
			.then(() => {
				fetchData();
				toastification.showToastDeleteSuccess();
			})
			.catch(error => {
				toastification.showToastError(error);
			});
	};

	//   Methods
	const onEditItem = val => {
		item.value = val;
	};
	const updateParams = newProps => {
		serverParams.value = { ...serverParams.value, ...newProps };
		fetchData();
	};

	const onPageChange = params => {
		updateParams({ start: (params.currentPage - 1) * serverParams.value.length });
	};

	const onPerPageChange = params => {
		updateParams({ length: params.currentPerPage });
	};

	const onSortChange = params => {
		const column = columns.map(_obj => _obj.field).indexOf(params[0].field);

		updateParams({
			order: [{
				dir: params[0].type,
				column,
			}],
		});
	};

	const onColumnFilter = params => {
		updateParams(params);
	};

	const selectionChanged = params => {
		selectedRows.value = params.selectedRows;
	};

	//   Watch
	watch(searchTerm, val => {
		updateParams({ filter: val });
	});
	watch(province, val => {
		updateParams({ province: val ? val._id : null });
	});

	// UI
	const resolveStatusVariant = val => {
		if (val === 'active') return { variant: 'primary', title: "Kích hoạt", icon: 'SmileIcon' };
		if (val === 'inactive') return { variant: 'warning', title: "Chưa kích hoạt", icon: 'MehIcon' };
		return { variant: 'danger', title: "Khóa", icon: 'FrownIcon' };
	};

	const resolveFamilyDoctorVariant = val => {
		if (val) return { variant: 'primary', title: "Có", icon: 'CheckIcon' };
		return { variant: 'danger', title: "Không", icon: 'MinusIcon' };
	};

	return {
		analytics,
		item,
		columns,
		rows,
		province,
		selectedRows,
		searchTerm,
		isLoading,
		totalRecords,
		serverParams,

		fetchData,
		deleteDepartmentOfHealth,
		onEditItem,
		resetItem,
		updateParams,
		onPageChange,
		onPerPageChange,
		onSortChange,
		onColumnFilter,
		selectionChanged,


		resolveStatusVariant,
		resolveFamilyDoctorVariant,
	};
}
